import React, { useState, useEffect } from 'react'
import PageTitle from '../../layout/pageTitle/PageTitle';

import { ACCESSIBILITY_STATEMENT } from '../../data/contentfulPages';
import useContentful from '../../useContentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
const options = {
    renderText: text =>
        text.split('\n').map((t, i) =>
            i > 0 ? (
                <React.Fragment key={`${i}-${t.slice(0, 5)}`}>
                    <br />
                    {t}
                </React.Fragment>
            ) : (
                t
            ),
        ),
}
const AccessibilityStatement = () => {
    const { getPage } = useContentful();
    const [page, setPage] = useState();

    useEffect(() => {
        getPage(ACCESSIBILITY_STATEMENT).then(res => setPage(res))
    }, [])

    return (
        <div className='page'>
            <div className=' wrapper'>
                <PageTitle title={page?.title ? page?.title : 'הצהרת נגישות'} />
                <div className=' text-container'>
                    {page?.description && documentToReactComponents(page?.description, options)}
                </div>
            </div>


        </div>
    )
}

export default AccessibilityStatement