import React from 'react'
import { useLocation } from "react-router-dom";


const SecondTopbar = ({ items }) => {
    const location = useLocation();

    return (
        <div id="second-navbar" className='topbar second'>
            <div className='wrapper'>
                <div className='left'>

                    {
                        items?.map((item, index) => (
                            <div
                                key={index}
                                className={'itemContainer'}>
                                <a
                                    aria-label={item.title}
                                    className={(location.pathname + location.hash === item.link.substring(1)) ? 'item active' : 'item'}
                                    href={item.link}
                                >
                                    <span>{item.title}</span>
                                </a>
                            </div>
                        ))

                    }

                </div>
            </div>
        </div>
    )
}

export default SecondTopbar