import React from "react";
import Slider from "react-slick";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./cards-slider.scss";

const CardsSlider = ({ items }) => {
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: false,
    //variableWidth: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {items?.map((card, index) => (
        <div key={index + 1} data-index={index}>
          <div className="slide-card">
            <h4 className="top-header">
              <b>{card.topTitle}</b>
            </h4>
            <div>
              {card.title ? (
                <>
                  <a
                    href={
                      card.projectId
                        ? `/project-details/${card.projectId}`
                        : "/projects"
                    }
                  >
                    <h4 className="header">{card.title}</h4>
                  </a>{" "}
                  <h5>{card.secondTitle}</h5>
                </>
              ) : (
                <h4 className="header">{card.secondTitle}</h4>
              )}
            </div>
            <div className="content">
              {documentToReactComponents(card.content)}
            </div>
            <img
              src={card.image ? card.image : "./images/project-in-proccess.jpg"}
              alt={card.title}
              className="card-img"
            />
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default CardsSlider;
