export const logoItem = {
    title: "",
    logo: "/logo-white.png",
    link: "/"
}
export const IntroItems = [
    {
        name: 'intro',
        title: 'מי אנחנו',
        link: './about/#intro'
    },
    {
        name: 'about',
        title: 'החזון',
        link: './about/#about'
    },
    {
        name: 'services',
        title: 'מחלקות החברה',
        link: './about/#services'
    },
    {
        name: 'teem',
        title: 'הצוות שלנו',
        link: './about/#team'
    },
]
export const EcoFriendlyItems = [
    {
        name: 'EcoFriendly',
        title: 'בנייה ירוקה',
        link: './eco-friendly/#intro'
    },
    {
        name: 'benefits',
        title: ' תקנים לבניה ירוקה',
        link: './eco-friendly/#benefits'
    },
]
export const UrbanRenewalItems = [
    {
        name: 'urbanRenewal',
        title: 'התחדשות עירונית',
        link: './urban-renewal/#intro'
    },
    {
        name: 'tama',
        title: ' סוגי התחדשות עירונית',
        link: './urban-renewal/#tama'
    },
    {
        name: 'projects',
        title: 'פרוייקטים בהתחדשות עירונית ',
        link: './urban-renewal/#projects'
    }

]
export const ProjectItems = [
    {
        name: 'all',
        pageTitle: 'הפרוייקטים שלנו',
        title: 'הכל',
        type: '',
        link: './projects/all'
    },
    {
        name: 'marketing',
        pageTitle: 'פרוייקטים בשיווק',
        title: 'פרוייקטים בשיווק',
        type: 'שיווק',
        link: './projects/marketing'
    },
    {
        name: 'inhabited',
        pageTitle: 'פרוייקטים מאוכלסים',
        title: 'פרוייקטים מאוכלסים',
        type: 'מאוכלס',
        link: './projects/inhabited'
    },

    {
        name: 'planning',
        pageTitle: 'פרוייקטים בתכנון',
        title: 'פרוייקטים בתכנון',
        type: 'תכנון',
        link: './projects/planning'
    }

]
export const menuItems = [
    {
        name: 'intro',
        title: 'אודות',
        link: '/about'
    },
    {
        name: 'projects',
        title: 'פרויקטים',
        link: '/projects'
    },
    {
        name: 'urban-renewal',
        title: 'התחדשות עירונית',
        link: '/urban-renewal'
    },
    {
        name: 'eco-friendly',
        title: ' בניה ירוקה',
        link: '/eco-friendly'
    },
    {
        name: 'איזור אישי',
        title: 'איזור אישי',
        link: '/login'
    },
    {
        name: 'contact',
        title: 'צור קשר',
        link: '/contact-us'
    }
]

export const ContactItems = [
    {
        name: 'contact',
        title: 'צור קשר',
        link: '/contact-us'
    },
    {
        name: 'איזור אישי',
        title: 'איזור אישי',
        link: '/login'
    },
    {
        name: 'accessibility',
        title: 'הצהרת נגישות',
        link: '/accessibility-statement'
    },
]