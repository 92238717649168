import React from "react";
import Slider from "react-slick";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./img-slider.scss";
const ArrowLeft = ({ onClick, className }) => (
  <button
    className={"arrow-btn prev_button prev" + className}
    onClick={onClick}
  >
    <img
      className="svg-icon"
      alt="previous"
      src="./images/icons/arrow-back-circle-outline.svg"
    />
  </button>
);
const ArrowRight = ({ onClick, className }) => (
  <button
    className={"image-btn next_button next" + className}
    onClick={onClick}
  >
    <img
      className="svg-icon"
      alt="next"
      src="./images/icons/arrow-forward-circle-outline.svg"
    />
  </button>
);
const ImageSlider = ({ images, title = "images title", slidesNumber = 1 }) => {
  console.log(slidesNumber);
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: slidesNumber,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: false,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    //variableWidth: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: slidesNumber,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: slidesNumber,
          slidesToScroll: 1,
          initialSlide: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {images?.map((image, index) => (
          <div key={index + 1} data-index={index}>
            <div className="slide">
              <img
                className="card-img"
                key={index}
                src={image.file?.url}
                alt={image?.title}
              />
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};
export default ImageSlider;
