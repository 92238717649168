import React, { useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import SecondTopbar from '../../layout/secondTopbar/SecondTopbar';
import { ProjectItems } from "../../data/menuItems";
import SecondTopbarMobile from "../../layout/secondTopbar/SecondTopbarMobile";


const ProjectsRoutes = () => {

    return (
        <>
            <SecondTopbar items={ProjectItems} />
            <SecondTopbarMobile items={ProjectItems}/>
            <Outlet />

        </>
    )


}

export default ProjectsRoutes





