import './Global.scss';
import './app.scss';
import { useEffect, useState } from "react"
//SEO 
import { PAGES_SEO } from './data/SEO';

import ProjectsRoutes from './components/routes/ProjectsRoutes';
import ProtectedRoutes from './components/routes/ProtectedRoutes';
//layout
import Footer from './layout/footer/Footer';
// components
import Topbar from "./components/topbar/Topbar";
import Menu from "./components/menu/Menu";

// pages
import LoginPage from './pages/user/LoginPage';
import Contact from "./pages/contact-us/Contact";
import Home from './pages/home/Home';
import ProjectsPage, { ProjectsFilterPage } from './pages/projects/ProjectsPage';
import UrbanRenewal from './pages/urban-renewal/UrbanRenewal';
import EcoFriendly from './pages/eco-friendly/EcoFriendly';
import ProjectDetails from './pages/project-details/ProjectDetails';
import AboutPage from './pages/about-page/AboutPage';
import AccessibilityStatement from './pages/accessibility-statement/AccessibilityStatement';
//aos
import AOS from "aos"
import "aos/dist/aos.css"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: "c-einav.firebaseapp.com",
//   projectId: "c-einav",
//   storageBucket: "c-einav.appspot.com",
//   messagingSenderId: "804997388994",
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  // //contentful
  // const [projects, setProjects] = useState([]);
  // const { getProjects } = useContentful();
  //aos
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <BrowserRouter>
      <div className="app" style={{ direction: "rtl" }}>
        <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />

        <Routes>
          {/* <Route path="*" element={<Home />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/contact-us" element={<Contact title={PAGES_SEO.contact.description} />} />

          <Route path="/project-details/:id" element={<ProjectDetails />} />
          <Route path="/urban-renewal" element={<UrbanRenewal />} />
          <Route path="/eco-friendly" element={<EcoFriendly />} />
          <Route path="/accessibility-statement" element={<AccessibilityStatement />} />

          <Route element={<ProtectedRoutes />} >
            {/* <Route path="/user" element={<UserPage />} /> */}
          </Route>
          <Route element={<ProjectsRoutes />} >
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/:type" element={<ProjectsFilterPage />} />
          </Route>
        </Routes>


        <Footer />
      </div>

    </BrowserRouter>
  );
}

export default App;
