import React, { useState } from 'react'
import './contact-us.scss';
import { ContactUsPage } from '../../data/pages';
import Intro from '../../components/intro/Intro';
import MetaComponent from "../../MetaComponent";
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';


const Contact = ({ title = "contact us", description = "" }) => {

  return (
    <>
      <MetaComponent
        title={title}
        description={description}
      />
      <div id="contact-us" className='page'>
        <div className="sections" style={{ overflow: 'hidden' }}>
          <div className='banner' style={{ backgroundImage: `url(${ContactUsPage.image})` }}>
            <div className='contact-us' >
              <h1 className='title'>{ContactUsPage.title}</h1>
              <p>{ContactUsPage.subTitle}</p>
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </>



  )
}

export default Contact