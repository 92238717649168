import React from 'react';
import PageTitle from '../../layout/pageTitle/PageTitle'
import './projects.scss'

const TO_PROJECT_DETAIL_TEXT="לצפייה בפרויקט";

const Projects = ({ title, subTitle, projects }) => {

    return (
        <div id="projects" className='projects page mt-60' >
            <div className='wrapper'>
                <PageTitle title={title} subTitle={subTitle} style={{ color: 'black' }} />
                <div className='projects-items' >
                    {projects?.map((project, index) => (
                        <div key={index}
                            className='item'
                            data-aos="flip-left"
                        // onClick={() => { if (project.display) navigate(`/project-details/${project.id}`) }}
                        >
                            <div className='image' style={{ backgroundImage: `url(${(project.image?.file?.url) || "../logo.png"})` }}></div>
                            <div className='overlay'></div>
                            <div className='details' >
                                <h1>{project.name}</h1>
                                <h2>{project.city}</h2>
                                {project.display && (
                                    <a className='project-btn' href={`/project-details/${project.id}`} aria-label={TO_PROJECT_DETAIL_TEXT} >
                                        {TO_PROJECT_DETAIL_TEXT}
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}



                </div>
            </div>

        </div>
    )
}

export default Projects


