import React from 'react'
import './spinner.scss'

const Spinner = () => {
  return (
    // <div className="loader"></div>
    <div>loading...</div>
  )
}

export default Spinner