import React from 'react'
import "./menu.scss";
import { menuItems } from "../../data/menuItems";
import { NavLink } from "react-router-dom";

export default function Menu({ menuOpen, setMenuOpen }) {

    return (
        <div className={'menu ' + (menuOpen && 'active')}>
            <ul>
                {menuItems.map((item, i) => (
                    <li key={i}  > 
                        <NavLink to={item.link} onClick={() => setMenuOpen(false)}>{item.title}</NavLink>
                    </li>
                ))}


            </ul>
        </div>
    )
}
