import React from 'react'
import PageTitle from '../../layout/pageTitle/PageTitle'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './about.scss';

const options = {
  renderText: text =>
    text.split('\n').map((t, i) =>
      i > 0 ? (
        <React.Fragment key={`${i}-${t. slice(0, 5)}`}>
          <br />
          {t}
        </React.Fragment>
      ) : (
        t
      ),
    ),
}


const About = ({ title, subTitle = '', content }) => {
  return (
    <div id="about" className='page'>

     <div className='wrapper'>
        <PageTitle title={title} subTitle={subTitle} style={{ color: 'black' }} />
        <div className='content'>
          {content.content ?
            documentToReactComponents(content, options) :
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          }
        </div>
      </div>
    </div>
  )
}

export default About