import React from 'react'
import PageTitle from '../../layout/pageTitle/PageTitle'
import { ServicesPage } from '../../data/pages';
import './services.scss'
const Services = () => {
  return (
    <div id="services" className='page'>
      <div className=' wrapper'>
        <PageTitle title={ServicesPage.title} subTitle={ServicesPage.subTitle} style={{ color: 'black' }} />

        <div className='items'>
          {ServicesPage.services.map((service, index) => (
            <div key={index} className='item' data-aos="flip-left">
              <div className='item-top'>
                <img src={service.image} alt={service.title} />
              </div>
              <div className='item-body'>
                <p className='semi-bold'>
                  {service.title}
                </p>

                <p className='desc'>
                {service.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  )
}

export default Services