import React from 'react'
import './login.scss'

const LoginPage = () => {
    const data = {
        title: "כניסה לאיזור אישי",
        content: " לשליחת קוד אישי למכשירך יש להזין את מספר הנייד:",
        image: "./images/login-background.png",
        buttonText: "שלחו קוד"

    }
    return (
        <div className='page'>
            <div className='login-banner' style={{ backgroundImage: `url(${data.image})` }}>
                <div className='login' >
                    <div className='logo'><img src='./logo-white-footer.png' alt="c-einav logo" /></div>
                    <h1 className='title'>{data.title}</h1>
                    <p>{data.content}</p>
                    <input type="phone" placeholder='טלפון נייד' aria-label='טלפון נייד' />
                    <button
                        aria-label="login"
                        className='btn'
                    >{data.buttonText}</button>
                </div>

            </div>


        </div>
    )
}

export default LoginPage