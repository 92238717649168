import React, { useEffect, useState } from 'react';
import About from '../../components/about/About';
import { homePage } from '../../data/pages';
import './home.scss'
import PageTitle from '../../layout/pageTitle/PageTitle';
import { ContactUsPage } from '../../data/pages';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';
import useContentful from '../../useContentful';
import { HOME_PAGE_ENTRY_ID } from '../../data/contentfulPages';
import CardsSlider from '../../components/cardsSlider/CardsSlider';

const LOGIN = 'login';

const Home = () => {
  const { getUpdates, getPage } = useContentful();
  const [updates, setUpdates] = useState([]);
  const [page, setPage] = useState();


  useEffect(() => {
    getPage(HOME_PAGE_ENTRY_ID).then(res => setPage(res))
    getUpdates().then((res) => { setUpdates(res) })
  }, [])

  return (
    <>
      <div className="home">
        <div className='banner' style={{ backgroundImage: `url(${(page?.banner.file.url)})` }}>
          {page?.videoUrl
            &&
            <iframe
              style={{ width: '100%', height: '100%' }}
              // src="https://www.youtube.com/embed/r48LHE1etSw?autoplay=0&mute=1&enablejsapi=1&rel=0&playsinline=1&showinfo=0&controls=0&origin=https://c-einav.com"
              // src="https://www.youtube.com/embed/r48LHE1etSw?showinfo=0&autoplay=0"
              src={`${page?.videoUrl.replace('watch?v=', 'embed/')}?autoplay=1&mute=1&enablejsapi=1&playsinline=1&showinfo=0&controls=0&origin=https://c-einav.com`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen="1"></iframe>
          }

        </div>


        {/* <div className='page'>
          <PageTitle title={'BREAKING NEWS'} />
          <SlideShowCards cards={updates} />
        </div> */}
        <div className='page'>
          <PageTitle title={'BREAKING NEWS'} />
          <div style={{ width: '80%', display: 'inline-block', margin: '0 auto', height: 'fit-content' }}>
            <CardsSlider items={updates} />
          </div>

        </div>

        <About title={page?.post[0]?.title || homePage.title} content={page?.post[0]?.content || homePage.content} />


        <div className='projects-category-items'>
          {homePage.projectsCategories.map((item, index) => (
            <div key={index} className='item'>
              <div className='image' style={{ backgroundImage: `url('${item.image}')` }}></div>
              <div className='overlay'></div>
              <div className='details' >
                {/* <h1>{item.title}</h1> */}
                <h2>{item.title}</h2>
                <a className='project-btn' href={item.link} aria-label={item.type} >{item.type}</a>
              </div>
            </div>
          ))}
        </div>

        <div className='projects-category-items'>
          <div className='item'>
            <div className='image' style={{ backgroundImage: `url("./images/login-background.png")` }}></div>
            <div className='overlay'></div>
            <div className='details' >
              <h1>{'דיירים VIP'}</h1>
              <h4 className='desc'>{'השארו מעודכנים בסטטוס הפרוייקטים, צפו בתוכניות הדירה ועקבו אחר ההתקדמות באיזור האישי שלכם'}</h4>
              <a className='project-btn' href={`/login`} aria-label={LOGIN} > {'איזור אישי'}</a>
            </div>
          </div>


        </div>
        <div className='contact-us home' >
          <PageTitle title={ContactUsPage.title} subTitle={ContactUsPage.subTitle} />
          <div className='wrapper' style={{ maxWidth: '700px' }}>
            <ContactUsForm />
          </div>

        </div>

      </div>
    </>

  )
}



export default Home
