import React from 'react';
import './team.scss';
import { TeamPage } from '../../data/pages';
import PageTitle from '../../layout/pageTitle/PageTitle'

const Team = () => {
    return (
        <div id="team" className='page'>
            <div className='wrapper'>
                <PageTitle title={TeamPage.title} subTitle={TeamPage.subTitle} style={{ color: 'black' }} />

                <div className='items'>
                    {TeamPage.members.map((member, index) => (
                        <div key={index} className='item' data-aos="zoom-in">
                            <div className='item-top'>
                                <div className='img-container'>
                                    <img src={member.image} alt={member.name} />
                                </div>
                                <h3>{member.name}</h3>
                            </div>
                            <div className='item-body'>
                                <h5>{member.role}</h5>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Team