import React from 'react'
import { UrbanRenewalPage } from '../../data/pages';
import Intro from '../../components/intro/Intro';
import Projects from '../projects/Projects';
import './urban-renewal.scss';
import PageTitle from '../../layout/pageTitle/PageTitle';
import { UrbanRenewalItems } from "../../data/menuItems";
import SecondTopbar from '../../layout/secondTopbar/SecondTopbar';
import MetaComponent from "../../MetaComponent";
import CardItem from '../../components/card/CardItem';

const tags = ['projects', 'פרוייקטים', 'התחדשות עירונית', 'Urban Renewal']

const UrbanRenewal = () => {
    const { intro, projects, tama } = UrbanRenewalPage;

    const data = {
        title: "title",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
    }
    return (
        <>
            <MetaComponent
                title={"התחדשות עירונית חברת שיא עינב"}
                description={tags.toString()}
            />
            <SecondTopbar items={UrbanRenewalItems} />


            <div className="sections">
                <Intro title={intro.title} content={intro.content} backgroundImage={intro.image} />

                <div id="tama" className='tama'>

                    <PageTitle title={tama.title} style={{ color: 'black' }} />

                    <div className='container'>
                        {tama?.tamaTypes?.map((item, i) => (
                            <div className='box' key={i}>
                                <h2 className='title'> {item.title}</h2>
                                {item.text.map((section, i) => (
                                    <p key={i}> {section}<br /><br /></p>
                                ))}
                            </div>

                        ))}
                    </div>
                </div>
                {/* <Projects title={"פרויקטים"} subTitle={"ב" + intro.title} projects={projects} /> */}
                {/* todo: tama check form */}
            </div>

        </>

    )
}

export default UrbanRenewal