import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from 'react-router-dom';


const userNavItems = [

    {
        title: 'user',
        url: 'user'
    },
    {
        title: 'projects',
        url: 'user/projects'
    },
]

const ProtectedRoutes = () => {
    const [isAuth, setIsAuth] = useState(true);
    return (<Outlet />)
    // useEffect(() => {
    //     axios.get("/api/get-token").then(function (data) {
    //         if (data.data.token) {
    //             setIsAuth(data.data.token);
    //         }
    //         return isAuth;
    //     })
    // }, [isAuth])

    // if (isAuth === undefined) return <LoginPage />;

    // return !isAuth ?
    //     (
    //         <Navigate to="/login" />
    //     )
    //     :
    //     (
    //         <>
    //             {/* <AdminNav navItems={adminNavItems} /> */}
    //             <div className='page-container  admin-page'>
    //                 <Outlet />
    //             </div>
    //         </>
    //     )





}

export default ProtectedRoutes





