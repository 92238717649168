import React from 'react';
import { IntroItems, EcoFriendlyItems, UrbanRenewalItems, ProjectItems, ContactItems } from "../../data/menuItems";

import './footer.scss';


const Footer = () => {
    const contactsDetailsRow = [
        { label: 'טלפון:', value: '04-6251401' },
        { label: 'טלפקס:', value: '04-6366399' },
        { label: 'טלפון למכירות:', value: '5246*' },
        { label: '', value: 'nitza.c.einav@gmail.com' },
        { label: 'משרד ראשי:', value: 'דרך רבין 1 פתח תקווה' },
        { label: 'משרדי הנהלה:', value: 'כפר הרא”ה, הרב ישראלי. ת.ד 37' }
    ];
    return (
        <>
            <div className='contact-row'>

                {contactsDetailsRow?.map((item, i) =>
                    (<p key={i}>{item.label} {item.value}</p>)
                )}

            </div>
            <footer>

                <div className='logo'>
                    <img src='/logo-white-footer.png' alt="c-einav logo" />
                </div>
                <div className='footer-items'>
                    <div className='item'>
                        <h1> אודות </h1>
                        <ul>
                            {IntroItems.map((item, indx) => (
                                <li key={indx}>
                                    <a className="link" href={item.link} aria-label={item.title}>{item.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='item'>
                        <h1> פרוייקטים </h1>
                        <ul>
                            {ProjectItems.map((item, indx) => (
                                <li key={indx}>
                                    <a className="link" href={item.link} aria-label={item.title}>{item.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='item'>
                         <h1> פרויקטים בשיווק בפתח תקווה </h1>
                         <ul>
                            <li><a class="link" href="https://c-einav.com/project-details/5xdG5Fkov0GHU3cogGAYyN" aria-label="חפץ חיים 25, המרכז השקט">חפץ חיים 25, המרכז השקט</a></li>
                            <li><a class="link" href="https://c-einav.com/project-details/3H2ODe6PTxip1qB5FcQc3v" aria-label="אורלנסקי 38, המרכז השקט">אורלנסקי 38, המרכז השקט</a></li>
                            <li><a class="link" href="https://c-einav.com/project-details/5BmiF4kRaPZDAoMXSn6qbv" aria-label="קטרוני 51, המרכז השקט">קטרוני 51, המרכז השקט</a></li>
                         </ul>     
                    </div>
                    <div className='item'>
                        <h1> התחדשות עירונית </h1>
                        <ul>
                            {UrbanRenewalItems.map((item, indx) => (
                                <li key={indx}>
                                    <a className="link" href={item.link} aria-label={item.title}>{item.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='item'>
                        <h1> בנייה ירוקה </h1>
                        <ul>
                            {EcoFriendlyItems.map((item, indx) => (
                                <li key={indx}>
                                    <a className="link" href={item.link} aria-label={item.title}>{item.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='item'>
                        <h1> אודות </h1>
                        <ul>
                            {ContactItems.map((item, indx) => (
                                <li key={indx}>
                                    <a className="link" href={item.link} aria-label={item.title}>{item.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </footer>
        </>


    )
}

export default Footer
