import './topbar.scss';
import { logoItem, menuItems } from "../../data/menuItems";
import { NavLink } from "react-router-dom";


export default function Topbar({ menuOpen, setMenuOpen }) {

  return (
    <>

      <div className='topbar-bg'></div>
      <div id="main-nav" className={'topbar ' + (menuOpen && 'active')} >
        <div className='wrapper'>
          <div className='left'>
            <a className='logo' href={logoItem.link}  aria-label={'home page'} >
              <img src={logoItem.logo} alt="logo" />
              <span> {logoItem.title}</span>
            </a>
            {
              menuItems.map((item, index) => (
                <div
                  key={index}
                  className={'itemContainer'}>
                  <NavLink
                   onClick={() => setMenuOpen(false)}
                    className='item'
                    tabIndex={index}
                    role="navigation" 
                    to={item.link}
                    aria-label={item.title}
                  >
                    {item.title}
                  </NavLink>

                </div>
              ))

            }

          </div>
          <div className='right'>
            <div
              className='hamburger'
              onClick={() => setMenuOpen(!menuOpen)}
              onKeyUp={() => setMenuOpen(!menuOpen)}
              // onKeyPress={() => setMenuOpen(!menuOpen)}
              role="button"
              tabIndex="0"
              aria-label="hamburger"
            >
              <span className='line1'></span>
              <span className='line2'></span>
              <span className='line3'></span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
