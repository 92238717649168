import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    host: process.env.REACT_APP_CONTENTFUL_HOST, //cdn
  });

  function formateDate(date, format = "d/m/y", divider = "/") {
    let dateArray = new Date(date).toLocaleDateString().split(divider); // return [$month, $day, $year]
    if (dateArray.length === 1) {
      return new Date(date).toLocaleDateString();
    }
    const formatedDate = format
      .replace("d", dateArray[1])
      .replace("m", dateArray[0])
      .replace("y", dateArray[2]);
    return formatedDate;
  }

  const getUpdates = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "update",
        select: ["fields", "sys"],
      });

      const sanitizedEntries = entries.items.map((item) => {
        const image = item.fields?.project?.fields?.image?.fields.file.url;
        const nameProject = item.fields?.project
          ? item.fields?.project?.fields?.name +
            " " +
            item.fields?.project?.fields?.city
          : "";
        const projectId = item.fields?.project?.fields?.display
          ? item.fields?.project?.sys?.id
          : null;
        const id = item.sys?.id;

        const updatedAt = item.fields?.date
          ? item.fields.date
          : item.sys.createdAt;

        const updatedAtFormat = formateDate(updatedAt);

        return {
          topTitle: updatedAtFormat,
          title: nameProject,
          secondTitle: item.fields.title,
          content: item.fields.content,
          projectId,
          image,
          updatedAt,
          id,
        };
      });

      sanitizedEntries.sort((a, b) => {
        var dateA = new Date(a.updatedAt);
        var dateB = new Date(b.updatedAt);

        // Sort in descending order (most recent first)
        return dateB - dateA;
      });
      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching updates: ${error}`);
    }
  };
  const getProjects = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "project",
        select: ["fields", "sys"],
        // order: "fields.name",
      });
      const sanitizedEntries = entries.items.map((item) => {
        const updatedAt = item.sys?.updatedAt;
        const image = item.fields?.image?.fields;
        const outsideImages = item.fields?.outsideImages?.map(
          (oi) => oi.fields
        );
        const insideImages = item.fields?.insideImages?.map((oi) => oi.fields);
        const id = item.sys.id;
        return {
          ...item.fields,
          image,
          updatedAt,
          outsideImages,
          insideImages,
          id,
        };
      });
      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching projects: ${error}`);
    }
  };
  const getProject = async (id) => {
    try {
      const entry = await client.getEntry(id);

      const image = entry.fields.image.fields;
      const outsideImages = entry.fields?.outsideImages?.map((oi) => oi.fields);
      const insideImages = entry.fields?.insideImages?.map((oi) => oi.fields);
      //  const id = entry.sys.id;
      const salePlans = entry.fields?.salePlans?.map((item) => ({
        ...item.fields,
        image: item.fields.image.fields,
        pdf: item.fields.pdf.fields,
      }));
      const sanitizedEntry = {
        ...entry.fields,
        image,
        salePlans,
        outsideImages,
        insideImages,
        //    id
      };
      return sanitizedEntry;
    } catch (error) {
      console.log(`Error fetching projects: ${error}`);
    }
  };

  const getPages = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "page",
        select: ["fields"],
        where: 'fields.title==="חזון ותפיסת ביצוע"',
        // order: "fields.name",
      });
      const sanitizedEntries = entries.items.map((item) => {
        const banner = item.fields?.banner?.fields;
        const articles = item.fields?.post?.map((oi) => oi.fields);
        const id = item.sys.id;
        return {
          ...item.fields,
          banner,
          articles,
          id,
        };
      });
      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching projects: ${error}`);
    }
  };

  const getPage = async (id) => {
    try {
      const entry = await client.getEntry(id);

      const banner = entry.fields?.banner?.fields;
      const posts = entry.fields?.post?.map((oi) => oi.fields);

      const sanitizedEntry = {
        ...entry.fields,
        banner,
        post: posts,
      };

      return sanitizedEntry;
    } catch (error) {
      console.log(`Error fetching projects: ${error}`);
    }
  };
  return { getUpdates, getProjects, getProject, getPages, getPage };
};

export default useContentful;
