
export const ContactUsPage = {
    title: "דברו איתנו",
    subTitle: "אנחנו כאן בשבילכם",
    submitBtn: "שלח",
    image: '../images/contact.png'
}

export const TeamPage = {
    title: " הכירו אותנו",
    subTitle: "ההנהלה",
    members: [
        {
            name: "אושר בן עזרי",
            role: "בעלים ומייסד קבוצת שיא עינב",
            image: "./images/team/אושר.jpg"
        },
        {
            name: "אלעד בן עזרי",
            role: "מנכ״ל",
            image: "./images/team/אלעד.jpg"
        },
        {
            name: "אסף בן עזרי",
            role: "מנהל פיתוח עסקי",
            image: "./images/team/אסף.jpg"
        },
        {
            name: "אייל בן עזרי",
            role: "יועץ משפטי",
            image: "./images/team/אייל.jpg"
        },
        {
            name: "אורלי כלפון",
            role: "הנהלת חשבונות",
            image: "./images/team/אורלי.jpg"
        },
        {
            name: "גלית חיון",
            role: "מנהלת מחלקת שיווק",
            image: "./images/team/גלית.jpg"
        },
        {
            name: "דוד לוי",
            role: "מנהל תחום ביצוע",
            image: "./images/team/דוד.jpg"
        },
        {
            name: "נטע מיימון",
            role: "מנהלת מחלקת שינויי דיירים",
            image: "./images/team/נטע.jpg"
        }
        ,
        {
            name: "ניצה גניש",
            role: "מנהלת משרד",
            image: "./images/team/ניצה.jpg"
        }
        ,
        {
            name: "מוחמד מחמיד",
            role: "מנהל תחום ביצוע שלד",
            image: "./images/team/מוחמד.jpg"
        }
    ]
}

export const introPage = {
    title: "מי אנחנו",
    image: "./images/office_about_us.jpeg",
    content: "חברת שיא עינב נוסדה בשנת 1993 על ידי בן עזרי אושר קבלן ויזם בעל ידע מקצועי וניסיון רב בענף הבנייה. שיא עינב בנתה ובונה מגוון פרוייקטים הכוללים עשרות יחידות דיור, בתים פרטיים, משרדים ומוסדות חינוך. מבנה המגורים והמבנים של החברה מפארים ערים וישובים רבים.<br/> שיא עינב בונה תוך שימוש בחומרים איכותים בלבד יחד עם אנשי מקצוע שעובדים במרץ תוך התייחסות לפרטים הקטנים ביותר. כדי להביא לתוצר מושלם מבחינת המראה והאיכות.<br/> שיא עינב הינה חברה בעלת גב כלכלי איתן הנהנת מצמיחה כלכלית מתמדת, החברה מעסיקה את מיטב המתכננים, המהנדסים ובעלי המקצוע לבניית דירות פרקטיות הנותנות מענה לצרכי הלקוח ולהנאתו המירבית.<br/> מנהלי החברה גאים ביכולתם לתת יחס אישי וחם לכל אחד מרוכשי הדירות, תוך מתן תשומת לב לטובתו של הלקוח, ליווי הדיירים למשך שנים ארוכות והקפדה על שירות מעולה גם לאחר מסירת הדירה.<br/> שיא עינב מוכרת בזכות אמינותה, מקצועיותה, איכות הבניה ללא פשרות ובזכות הקשר האישי עם הלקוחות.",
    more: ""
}

export const ServicesPage = {
    title: "מחלקות החברה",
    subTitle: "",
    services: [
        {
            title: "התחדשות עירונית",
            desc: "אחריות מקצועית בתחום הבטיחות, בקרת האיכות והבדק. בנכסים ובאתרי הבנייה של החברה",
            image: "./images/icons/cityscape.png"
        },
        {
            title: "ביצוע",
            desc: "תיאום ותכנון, הוצאת היתרים, בנייה, טופס 4 ומסירת הדירות ללקוחות החברה",
            image: "./images/icons/builder.svg"
        },
        {
            title: "יזום",
            desc: "ניתוח פרויקטים חדשים",
            image: "./images/icons/idea.svg"
        },
        {
            title: "שיווק",
            desc: "אסטרטגיה ומתן מענה למתעניינים ברכישת דירה בפרויקטים השונים. העברת מידע וליווי עד חתימת חוזה הרכישה",
            image: "./images/icons/marketing.svg"
        },
        {
            title: "תיאום תכנון",
            desc: "סנכרון תוכניות בין ההיועצים בפרויקטים וקידום הליך הרישוי מול רשויות התכנון",
            image: "./images/icons/planing.svg"
        },
        {
            title: " שינויי דיירים",
            desc: "תיאום אדריכלי וליווי רוכשי הדירות בשינויים/שידרוגים באופן פרטני ומקצועי בכל מהלך הבנייה",
            image: "./images/icons/shinui_dayarim.png"
        },
        {
            title: "בדק",
            desc: "מתן מענה בתקופת הבדק לטיפול בקריאות שירות מדיירים",
            image: "./images/icons/checking.svg"
        },
        {
            title: "משפטית",
            desc: "הפעילות בחברה מלווה בפן המשפטי ומטפלת במגוון נושאים לרבות טיפול בתביעות משפטיות, דיני עבודה, הסכמים, חוזים וכדומה",
            image: "./images/icons/laws.svg"
        }
    ]
}

export const ProjectsPage = {
    title: "הפרויקטים שלנו",
    subTitle: "",
    projects: [
        {
            title: "פרוייקט 1",
            name: "proj1",
            image: "../images/projects/3.jpg"
        },
        {
            title: "פרוייקט 2",
            name: "proj2",
            image: "../images/projects/4.jpg"
        },
        {
            title: "פרוייקט 3",
            name: "proj3",
            image: "../images/projects/3.jpg"
        },
        {
            title: "פרוייקט 4",
            name: "proj4",
            image: "../images/projects/4.jpg"
        }
    ]
}
export const homePage = {
    title: "נעים להכיר",
    subTitle: "",
    content: "חברת <b>שיא עינב</b> בע”מ הינה חברת בנייה המתמחה בבניה רוויה בסטנדרט גבוה באזורי השרון והמרכז. <br/>החברה מאמינה בפרויקטי בוטיק, איכות ללא פשרות ושירות לקוחות מהדרגה הראשונה <br/>חזון החברה מושתת על ערכי הליבה המניעים את היזמות ואת העשייה. מצויינות, משפחתיות, דייקנות והתחדשוות מתמדת.<br/><br/>, חברת שיא עינב נוסדה מתוך תפיסה ניהולית הדוגלת בריכוז כלל הפרויקטים תחת גג אחד, בחברת האם.<br/> כך מתאפשר לבצע את תהליך הבנייה באופן יעיל יותר ופשוט, ללא סיבוכים וסרבולים מיותרים עבורך, הלקוח.<br/> החברה לוקחת אחריות ומבצעת את תהליכי הבניה לכל אורך הדרך – משלב התכנון הראשוני, דרך הבנייה עצמה ועד למסירת הדירות, הכל תוך בקרת איכות מתמדת ותשומת לב יסודית ומעמיקה לצרכי לקוחות החברה. <br/>החברה מעסיקה בעלי מקצוע מיומנים ובעלי נסיון רב בתחומם – מהנדסים, מנהלי פרויקטים, ועובדי בניה משלב השלד ועד שלב הגמר בעלי ותק מקצועי עשיר בענף הבניה למגורים.<br/> החברה דוגלת באיכות בניה ללא פשרות, מקצועיות, יעלות, עמידה בלוחות זמנים ושירות מיטבי, אשר כלל הבסיס בו הוא: הלקוח שלנו עומד במרכז.",
    projectsCategories: [
        {
            title: "NEW & EXCTING",
            type: "פרויקטים בתכנון",
            image: "./images/sketches.jpeg",
            link: './projects/planning'
        },
        {
            title: "NEW & EXCTING",
            type: "פרויקטים בבנייה ושיווק" ,
            image: "./images/project-in-proccess.jpg",
            link: './projects/marketing'
        },
        {
            title: "NEW & EXCTING",
            type: "פרויקטים מאוכלסים",
            image: "./images/family_house.jpeg",
            link: './projects/inhabited'
        }

    ]
}
export const aboutPage = {
    title: "חזון ותפיסת ביצוע",
    subTitle: "",
    content: "חברת <b>שיא עינב</b> בע”מ הינה חברת בנייה המתמחה בבניה רוויה בסטנדרט גבוה באזורי השרון והמרכז. <br/>החברה מאמינה בפרויקטי בוטיק, איכות ללא פשרות ושירות לקוחות מהדרגה הראשונה <br/>חזון החברה מושתת על ערכי הליבה המניעים את היזמות ואת העשייה. מצויינות, משפחתיות, דייקנות והתחדשוות מתמדת.<br/><br/>, חברת שיא עינב נוסדה מתוך תפיסה ניהולית הדוגלת בריכוז כלל הפרויקטים תחת גג אחד, בחברת האם.<br/> כך מתאפשר לבצע את תהליך הבנייה באופן יעיל יותר ופשוט, ללא סיבוכים וסרבולים מיותרים עבורך, הלקוח.<br/> החברה לוקחת אחריות ומבצעת את תהליכי הבניה לכל אורך הדרך – משלב התכנון הראשוני, דרך הבנייה עצמה ועד למסירת הדירות, הכל תוך בקרת איכות מתמדת ותשומת לב יסודית ומעמיקה לצרכי לקוחות החברה. <br/>החברה מעסיקה בעלי מקצוע מיומנים ובעלי נסיון רב בתחומם – מהנדסים, מנהלי פרויקטים, ועובדי בניה משלב השלד ועד שלב הגמר בעלי ותק מקצועי עשיר בענף הבניה למגורים.<br/> החברה דוגלת באיכות בניה ללא פשרות, מקצועיות, יעלות, עמידה בלוחות זמנים ושירות מיטבי, אשר כלל הבסיס בו הוא: הלקוח שלנו עומד במרכז.",
}
export const AboutUsPage = {
    title: "נעים להכיר",
    subTitle: "",
    content: "חברת שיא עינב בע”מ הינה חברת בנייה המתמחה בבניה רוויה בסטנדרט גבוה באזורי השרון והמרכז. החברה מאמינה בפרויקטי בוטיק, איכות ללא פשרות ושירות לקוחות מהדרגה הראשונה חזון החברה מושתת על ערכי הליבה המניעים את היזמות ואת העשייה. מצויינות, משפחתיות, דייקנות והתחדשוות מתמדת.",
}

export const UrbanRenewalPage = {
    intro: {
        title: "התחדשות עירונית",
        image: "/images/urban-renewal.jpg",
        content: "התחדשות עירונית הינו תהליך מורכב וארוך, המצריך ניסיון רב, מקצועיות וליווי אישי של לקוחות. השילוב הייחודי בין יזמות ליכולות ביצוע גבוהות של החברה, מאפשר להעניק בטחון ושקט נפשי לדיירים, מתחילת התהליך ועד קבלת המפתח. אנו מזמינים אתכם ליהנות מדירה חדשה בסביבה מטופחת ומשודרגת."
    },
    tama: {
        title: 'סוגי התחדשות עירונית',
        tamaTypes: [
            {
                title: 'תמ"א 38/1- עיבוי וחיזוק מבנים',
                text: [
                    'תמ"א 38, או בשמה המלא תכנית מתאר ארצית 38 מתייחסת <b>לחיזוק</b> מבנים קיימים בפני רעידות אדמה. תכנית זו נועדה להגדיר אילו מבנים אינם עומדים בתקן הישראלי – ת"י 413 – או במילים אחרות, הבניינים שעבורם נדרשות עבודות חיזוק, בכדי שאלה יוכלו להתמודד עם הכוחות האופקיים המופעלים על בסיס המבנה בעת התרחשות רעידת אדמה. התכנית כוללת קבלת היתרי בנייה לחיזוק מבנים, תוספות בנייה, פטורים ממס שבח ופטורים משמעותיים נוספים .',
                    ' מרבית המבנים שנבנו בישראל עד שנת 1980 אינם עומדים בדרישות התקן הישראלי. מדינת ישראל יושבת על אזור רווי רעידות, ועל כן, המדינה הבינה כי הצורך בביצוע חיזוק מבנים נגד רעידות אדמה הוא הכרחי ונדרש. התכנית הוצגה ביוזמת המועצה הארצית לתכנון ולבנייה, ואושרה על ידי ממשלת ישראל ב-14 באפריל 2005. במסגרת מימוש זכויות לפי תמ"א 38 פטורים הדיירים מכל חובות מס, וכל העסקה פטורה ממס עבור הדיירים.',
                   
                ]
                 
            },
            {
                title: 'תמ"א 38/2 – הריסה ובנייה מחדש',
                text: [
                        'בשונה מתהליך "עיבוי וחיזוק מבנים" אשר בו כאמור, מבצעים חיזוק לבניין הישן שאינו עומד בתקן 413, בסוג התחדשות עירונית זה, מבצעים <b>הריסה</b> לאותו הבניין ובמקומו בונים בניין חדש לגמרי, בזהה לבנייה על מגרש ריק. תמ"א 38/2 מהווה למעשה תיקון לאותה התוכנית המאפשרת את חיזוק הבניין. ',
                        'אנחנו בחברת שיא עינב מתמקצעים בפרויקטים מסוג זה, פרויקטים המאפשרים לנו להביא לידי ביטוי את יכולת החברה להעניק ללקוחתנו מוצר איכותי וייחודי, כזה שלא רואים בכל פינה בעיר, כל זאת תוך שמירה על יחס חם ומשפחתי לכל אחד מלקוחתיה.'
                        ]
            },
            {
                title: 'פינוי בינוי',
                text: [
                    'הכרזה על מתחם פינוי לשם בינוי היא בסמכותה של ממשלת ישראל על פי סעיף 33א. לחוק התכנון והבניה, התשכ"ה-1965. ',
                    ' יוזמות פינוי בינוי מוסדרות בחוק בינוי ופינוי של אזורי שיקום, התשכ"ה-1965. בשנת 2006 חוקקה הכנסת את חוק פינוי ובינוי (פיצויים), התשס"ו-2006 במטרה להתגבר על קשיים שגורמים דיירים בודדים בבתים משותפים המתנגדים להסכמי פינוי בינוי, למול רוב גורף של שאר דיירי הבניין. התנגדות כזו באה לעתים על רקע ניסיון לזכות בהסדר כלכלי עדיף על פני שאר הדיירים.',
                    ' בעל דירה, אשר מסרב סירוב בלתי סביר להעביר את זכויותיו לשם ביצוע עסקת פינוי בינוי, או מתנה את הסכמתו בתנאים בלתי סבירים, אחראי בנזיקין כלפי שאר בעלי הדירות באותו בניין. במסגרת פרויקט פינוי- בינוי יש להעמיד לטובת בעלי הדירות בטחות נאותות אשר יבטיחו את קניינם: ערבות חוק מכר – תנאי לפינוי הדירה הישנה הוא קבלת ערבות בנקאית (חוק מכר) בשווי הדירה החדשה כשהיא צמודה למדד תשומות הבנייה.',
                    ' ערבות בנקאית אוטונומית להבטחת תשלום שכר דירה – תנאי לפינוי הדירה הישנה יהיה קבלת ערבות בנקאית אוטונומית להבטחת תשלום דמי שכירות בד בבד עם מתן התשלומים בפועל. כמו- כן יש לפעול בשקיפות וביעילות ולבחור נציגות וועד אשר תעבוד בשיתוף פעולה הדוק מול החברה היזמית שנבחרה לביצוע הפרויקט.',
                    ' לאורך השנים ניהלה החברה פרויקטים מסוג פינוי בינוי משמעותיים, ולא פחות חשוב – דורגה על ידי הדיירים, כגוף בעל תודעת שירות ויכולות ליווי בלתי רגילות.']
            }
        ]
    },
    projects: [
        {
            title: "פרוייקט 1",
            name: "proj1",
            image: "../images/projects/3.jpg"
        },
        {
            title: "פרוייקט 2",
            name: "proj2",
            image: "../images/projects/4.jpg"
        },
        {
            title: "פרוייקט 3",
            name: "proj3",
            image: "../images/projects/3.jpg"
        },
        {
            title: "פרוייקט 4",
            name: "proj4",
            image: "../images/projects/4.jpg"
        }
    ]
}
export const EcoFriendlyPage = {
    intro: {
        title: "בניה ירוקה",
        image: "./images/eco-building.jpeg",
        content: "שיא עינב מאמינה ותומכת בעתיד ירוק בישראל, ושמחה לבנות פרויקטים בסטנדרטים ירוקים, תוך בחירת חומרים נכונה, שמירה על משאבים טבעיים ושיפור איכות החיים לדיירי הפרויקט. אנו מזמינים גם אתכם להצטרף למהפכת הבנייה הירוקה של שיא עינב וליהנות מכל היתרונות."

    },
    featuresTitle: "יתרונות לבניה ירוקה",
    features: [
        {
            title: "השפעות כלכליות",
            image: "./images/icons/icon2.svg",
            text: "בנייה ירוקה יכולה להביא לחסכון של עד 30% מצריכת האנרגיה בהשוואה לבניין קונבנציונלי."
        },
        {
            title: "השפעות בריאותיות",
            image: "./images/icons/icon1.svg",
            text: "מחקרים מצאו כי לבנייה ירוקה יש השפעה בריאותית חיובית על דיירי הפרויקט."

        },
        {
            title: "יעילות אנרגטית",
            image: "./images/icons/icon3.svg",
            text: "שימוש יעיל באנרגיה ובמשאבים אחרים."

        },
        {
            title: "הפחתת זיהום",
            image: "./images/icons/icon4.svg",
            text: "הפחתת פסולת, שימוש בחומרים שאינם רעילים ומיחזור באיכות טובה."

        },

    ]
}
export const PricesPage = {
    title: "Prices",
    subTitle: "fill the form and we contact you back",
}

export const ProjectDetailsPage = {
    features: [
        {
            title: "דירות מרווחות",
            image: "./images/icons/apartment.jpg",
        },
        {
            title:"סביבה ירוקה",
            image: "./images/icons/eco-env.jpg",
        },
        {
            title:"מיקום מרכזי",
            image: "./images/icons/location.jpg",
        },
        {
            title: "חינוך איכותי",
            image: "./images/icons/education.jpg",
        },
        {
            title:"בניה בסטנדרטים גבוהים",
            image: "./images/icons/building-standart.jpg",
        },
        {
            title:"נגישות לכבישים מרכזיים",
            image: "./images/icons/road.jpg",
        },

    ]
}
