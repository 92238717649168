import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../layout/pageTitle/PageTitle";
import { ContactUsPage, ProjectDetailsPage } from "../../data/pages";
import ContactUsForm from "../../components/contact-us-form/ContactUsForm";
import Features from "../../components/features/Features";
import { Link } from "react-router-dom";
import ImagesSlider from "../../components/imagesSlider/ImagesSlider";
//contentful
import useContentful from "../../useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import "./project-details.scss";
import ImageSlider from "../../components/imageSlider/ImageSlider";

const options = {
  renderText: (text) =>
    text.split("\n").map((t, i) =>
      i > 0 ? (
        <Fragment key={`${i}-${t.slice(0, 5)}`}>
          <br />
          {t}
        </Fragment>
      ) : (
        t
      )
    ),
};
const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [plan, setPlan] = useState({});
  const { getProject } = useContentful();
  const [open, setOpen] = useState(0);
  const [groups, setGroups] = useState({});
  const [outsideImagesIndex, setOutsideImagesIndex] = useState(1);
  const [insideImagesIndex, setInsideImagesIndex] = useState(1);
  const toggle = (item) => {
    if (open === item) {
      setOpen("");
    } else {
      setOpen(item);
    }
  };
  useEffect(() => {
    getProject(id)
      .then((res) => {
        setProject(res);
        if (res?.salePlans) {
          setPlan(res.salePlans[0].image);
        }
        const groups = res.salePlans.reduce(
          (groups, item) => ({
            ...groups,
            [item.type]: [...(groups[item.type] || []), item],
          }),
          {}
        );
        setGroups(groups);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const nextImage = () => {
    if (project?.outsideImages?.length > 1) {
      const i = (outsideImagesIndex % project?.outsideImages?.length) + 1;
      setOutsideImagesIndex(i);
    }

    if (project?.insideImages?.length > 1) {
      let roundIndex2 = (insideImagesIndex + 1) % project?.insideImages?.length;
      setInsideImagesIndex(roundIndex2);
    }
  };

  const backImage = () => {
    if (project?.outsideImages?.length > 1) {
      const i = ((outsideImagesIndex + 1) % project?.outsideImages?.length) + 1;
      setOutsideImagesIndex(i);
    }
    if (project?.insideImages?.length > 1) {
      let i2 = (insideImagesIndex + 1) % project?.insideImages?.length;
      setInsideImagesIndex(i2);
    }
  };
  return (
    <div className="page">
      <div
        className="img-background"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.467), rgba(74, 68, 68, 0.822)), url('${project?.image?.file?.url}')`,
        }}
      >
        <h1 className="title">{project?.name}</h1>
        <h2 className="sub-title">{project?.city}</h2>
      </div>
      <div className="wrapper">
        <PageTitle
          title={project?.descTitle || "THE NEW LIVING STANDARD"}
          style={{ color: "black" }}
        />
        <div className="project-details">
          <div className="text-container">
            {documentToReactComponents(project?.description, options)}
          </div>
        </div>
      </div>
      <div className="project-features">
        <Features features={ProjectDetailsPage.features} />
      </div>
      {/* //neighborhood and location */}
      <div className="boxes">
        {project?.location && (
          <div className="box">
            <iframe
              title="מיקום פרוייקט במפה"
              src={`https://maps.google.com/maps?q=${project?.location.lat},${project?.location.lon}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
              style={{ border: 0, width: "100%", height: "100%" }}
              loading="lazy"
            ></iframe>
          </div>
        )}

        <div className="box p-50">
          <h1 className="title">THE PERFECT NEIGHBORHOOD</h1>
          <h3 className="sub-title">הסביבה</h3>
          <div className="text-container">
            {documentToReactComponents(project?.neighborhood)}
          </div>
        </div>
      </div>
      {/* about the project */}
      {/* {project?.neighborhood && (
        <div className="wrapper m-50">
          <PageTitle
            title={project?.neighborhoodTitle || "WRAPPED WITH MAGIC"}
            subTitle={" הפרויקט"}
            style={{ color: "black" }}
          />
          <div className="project-details">
            <div className="text-container">
              {documentToReactComponents(project?.neighborhood, options)}
            </div>
          </div>
        </div>
      )} */}
      {/* photos of project */}
      <div className="boxes">
        {project?.outsideImages && (
          <div className="slider-container">
            <ImageSlider
              images={project?.outsideImages}
              slidesNumber={
                project?.insideImages || project?.outsideImages.length === 1
                  ? 1
                  : 2
              }
            />
            <div className="images-title">
              <p>{"הדמיית חוץ"}</p>
            </div>
          </div>
        )}

        {project?.insideImages && (
          <div className="slider-container">
            <ImageSlider
              images={project?.insideImages}
              slidesNumber={
                project?.outsideImages || project?.insideImages.length === 1
                  ? 1
                  : 2
              }
            />
            <div className="images-title">
              <p>{"הדמיית פנים"}</p>
            </div>
          </div>
        )}
      </div>
      {/* <div className="boxes">
        {project?.outsideImages && (
          <div className="box">
            <ImagesSlider images={project?.outsideImages} title="הדמיית חוץ" />
          </div>
        )}
        {project?.insideImages && (
          <div className="box">
            <ImagesSlider images={project?.insideImages} title="הדמיית פנים" />
          </div>
        )}
      </div> */}
      {project?.aboutApartments && (
        <div className="wrapper m-50">
          <PageTitle
            title={project?.descTitles || "WRAPPED WITH MAGIC"}
            subTitle={"קולקציית הדירות"}
            style={{ color: "black" }}
          />
          <div className="project-details">
            <div className="text-container">
              {documentToReactComponents(project?.aboutApartments, options)}
            </div>
          </div>
        </div>
      )}
      {project?.salePlans && (
        <div className="boxes">
          <div className="box file">
            <div className="img-container">
              <img src={plan?.file?.url} alt={plan?.title} />
            </div>
          </div>

          <div className="box p-50 sketch-plans">
            <h2>תוכניות דירה</h2>
            {Object.keys(groups).map((item, i) => (
              <Fragment key={i}>
                <div
                  className="collapse-header"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => toggle(item)}
                  onClick={() => toggle(item)}
                >
                  <button
                    className={item === i ? "icon-btn active" : "icon-btn"}
                  >
                    <img
                      className="icon"
                      aria-label="arrow down"
                      src="./images/icons/feather-arrow-down-circle.svg"
                    />
                  </button>
                  {item}
                </div>
                <ul className={`${open == item ? "open" : ""}`}>
                  {groups[item].map((p, j) => (
                    <li
                      key={j}
                      className={`${
                        p.image === plan
                          ? "collapse-body active"
                          : "collapse-body"
                      }`}
                    >
                      <span
                        className="head-title"
                        onClick={() => {
                          setPlan(p.image);
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {
                          setPlan(p.image);
                        }}
                      >
                        {p.type} - {p.name}
                      </span>
                      <Link
                        className={"icon-btn"}
                        to={p?.pdf?.file?.url}
                        target="_blank"
                      >
                        <img
                          className="icon"
                          aria-label="open pdf file"
                          src="./images/icons/file-icon.svg"
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ))}
          </div>
        </div>
      )}
      {/* contact us  */}
      <div className="contact-us home m-50">
        <PageTitle
          title={ContactUsPage.title}
          subTitle={ContactUsPage.subTitle}
        />
        <div className="wrapper p-20" style={{ maxWidth: "700px" }}>
          <ContactUsForm />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
