import React from 'react';
import './features.scss'

const Features = ({ features }) => {
    return (

        <div className='features-items'>
            {features.map((item, index) => (
                <div key={index} className='item' >
                    <div className='item-image'><img src={item.image} alt={item.title} /></div>
                    <div className='item-body'>
                        <p className='semi-bold'>{item.title}</p>
                        <p className='desc'>{item.text}</p>
                    </div>

                </div>
            ))}
        </div>

    )
}

export default Features