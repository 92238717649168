import React, { useState } from 'react'
import Spinner from '../../layout/Spinner/Spinner';
import './contact-us.scss';
import { ContactUsPage } from '../../data/pages';
import Intro from '../../components/intro/Intro';
import MetaComponent from "../../MetaComponent";
const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}
const contactField = [
    {
        name: 'name',
        type: 'text',
        label: 'שם מלא',
        required: true,
        minLength: 2,
    },
    {
        name: 'phone',
        type: 'phone',
        label: 'נייד',
        required: true
    },
    {
        name: 'email',
        type: 'email',
        label: 'דואר אלקטרוני',
        required: true
    },
    {
        name: 'content',
        type: 'textarea',
        label: 'הודעה',
        required: true
    },
]
const ContactUsForm = () => {
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [fields, setFields] = useState(contactField)
    const [errors, setErrors] = useState({})
    const getValue = (e) => {
        switch (e.target.type) {
            case 'checkbox':
                return e.target.checked
            default:
                return e.target.value
        }
    }

    const onChange = (e) => {
        const value = getValue(e);
        const error = e.target.validationMessage;

        setErrors({ ...errors, [e.target.name]: error })

        setValues({ ...values, [e.target.name]: value })
    };
    const sendForm = () => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...values })
        })
            .then(() => alert("Success!"))
            .catch(error => alert(error));

    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        //  console.log(values);

        if (e.currentTarget.checkValidity() === true) {

            sendForm()
        }

        setValidated(true);
    };
    return (
        <form
            name="contact"
            className='contact'
            noValidate
            validated={validated.toString()}
            onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            {fields?.map((field, index) =>

            (
                <div className='input-wrapper' key={index}>
                    <input
                        value={values[field.name] || ''}
                        name={field.name}
                        aria-label={field.label}
                        type={field.type}
                        placeholder={field.label}
                        onChange={(e) => onChange(e)}
                        {...(field.required && { required: true })}
                        {...(field.minLength && { minLength: field.minLength })}
                    />
                    <div className='errors'>
                        {<p>{errors[field.name]}</p>}
                    </div>
                </div>
            )

            )}
            <div className='radio-input-wrapper'>
                <input type="checkbox" id="approve" value={values['approve']} onChange={(e) => onChange(e)} aria-label="approve contract" name={'approve'} />
                <label htmlFor="approve">הנני מאשר/ת קבלת פניות שיווקיות לדוא״ל/ SMS והרשמה למאגרי המידע של חברת שיא עינב.</label>
            </div>
            {/* <div className='errors'>
                {Object.keys(errors).map((i, err) => (<p key={i}>{errors[i]}</p>))}
            </div> */}
            <button
                type="submit"
                id="submit"
                className="btn"
                aria-label="submit contact us form"
               
            >
                {loading === true ? (
                    <Spinner />

                ) : (
                    ""
                )}
                {ContactUsPage.submitBtn}
            </button>

        </form>
    )
}

export default ContactUsForm