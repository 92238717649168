import React, {useEffect} from 'react';
import Intro from '../../components/intro/Intro';
import About from '../../components/about/About';
import { aboutPage } from '../../data/pages';
import Services from '../../components/our-services/Services';
import Team from '../../components/team/Team';
import { introPage } from '../../data/pages';
import { IntroItems } from "../../data/menuItems";
import SecondTopbar from "../../layout/secondTopbar/SecondTopbar";
import MetaComponent from "../../MetaComponent";
import { ABOUT_PAGE_ENTRY_ID } from '../../data/contentfulPages';
import useContentful from '../../useContentful';
import { useState } from 'react';

const tags = [
  'c einav', 'שיא עינב', 'אושי בן עזרי', 'דירות', 'חברת בנייה ויזמות'
]
const AboutPage = () => {
  const { getPage } = useContentful();
  const [page, setPage] = useState();

  useEffect(() => {
    getPage(ABOUT_PAGE_ENTRY_ID).then(res => setPage(res))
  }, [])

  return (
    <>
      <MetaComponent
        title={"אודות חברת שיא עינב"}
        description={tags.toString()}
      />
      <SecondTopbar items={IntroItems} />
      <div className="sections">
        <Intro title={page?.title || introPage.title} content={(page?.description || introPage.content)} backgroundImage={introPage.image} />
        <About title={page?.post[0]?.title || aboutPage.title} content={page?.post[0]?.content || aboutPage.content} />
        <Services />
        <Team />
      </div>

    </>
  )
}

export default AboutPage