import React, { useEffect, useState } from 'react';
import Projects from './Projects';
import { ProjectsPage as page } from '../../data/pages';
//contentful
import useContentful from '../../useContentful';
import MetaComponent from "../../MetaComponent";
import { useParams, useNavigate } from 'react-router-dom';
import { ProjectItems } from "../../data/menuItems";

const tags = ['projects', 'פרוייקטים']
const ALL_PROJECTS = 'כל הפרוייקטים';

export const ProjectsFilterPage = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [filterType, setType] = useState(ProjectItems[0]);
  const { getProjects } = useContentful();
  //contentful
  const [filterProjects, setFilterProjects] = useState([]);

  useEffect(() => {
    const item = ProjectItems.filter((item) => item.name === type)[0];

    if (!item) {
      navigate('/projects');
    }
    setType(item)
    getProjects().then((res) => {
      const filtered = res.filter((v) => v.type.includes(item.type))
      setFilterProjects(filtered)
    })

  }, [type, navigate])


  return (
    <>
      <MetaComponent
        title={`פרויקטים חברת שיא עינב ${filterType.title}`}
        description={tags.toString()}    
      />
      {/* <ProjectsFilters items={types} onFilter={HandleFilter} filter={filter} /> */}

      <Projects title={filterType.pageTitle} subTitle={page.subTitle} projects={filterProjects} />

    </>
  )
}



const ProjectsPage = () => {
  const { getProjects } = useContentful();
  //contentful
  const [projects, setProjects] = useState([]);
  // const [filterProjects, setFilterProjects] = useState([]);
 
  useEffect(() => {
    getProjects().then((res) => {
      setProjects(res);
    })
  }, [])


  return (
    <>
      <MetaComponent
        title={"פרויקטים חברת שיא עינב"}
        description={tags.toString()}
      />
      <Projects title={ALL_PROJECTS} subTitle={page.subTitle} projects={projects} />

    </>
  )
}

export default ProjectsPage

// const ProjectsFilters = ({ items, onFilter, filter }) => {

//   const FilterBy = (type) => {
//     onFilter(type)
//     console.log(type)
//   }
//   return (
//     <div id="second-navbar" className='topbar second'>
//       <div className='wrapper'>
//         <div className='left'>

//           {
//             items?.map((item, index) => (
//               <div
//                 key={index}
//                 className={'itemContainer'}>

//                 <button
//                   aria-label={item}
//                   style={{ border: 'none', background: 'transparent' }}
//                   className={(filter === item) ? 'item filterButton active' : 'item filterButton'}
//                   onClick={() => FilterBy(item)}
//                 >
//                   <span>{item}</span>
//                 </button>
//               </div>
//             ))

//           }

//         </div>
//       </div>
//     </div>
//   )
// }