import React from 'react';
import './intro.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
const options = {
  renderText: text =>
    text.split('\n').map((t, i) =>
      i > 0 ? (
        <React.Fragment key={`${i}-${t. slice(0, 5)}`}>
          <br />
          {t}
        </React.Fragment>
      ) : (
        t
      ),
    ),
}
const Intro = ({ title, content, backgroundImage, children }) => {

  return (
    <div
      className='intro'
      style={{ backgroundImage: `url(${(backgroundImage) || "../logo.png"})` }}
    >
      <div className='wrapper'>
        <h1 className='title'>{title}</h1>

        <div className='content'>
          {content.content ? documentToReactComponents(content,options) :
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          }
        </div>

        {children}

      </div>

    </div>

  )
}

export default Intro