import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import './second-topbar.scss';


const PROJECT_TYPE_TEXT = "סוג הפרוייקט"

const SecondTopbarMobile = ({ items }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(false)
    }, [])

    return (
        <div id="second-navbar-mobile" className={open ? 'open topbar-second-mobile' : 'topbar-second-mobile'}>
            <button className='text-btn toggle-text' onClick={() => setOpen(!open)} >{PROJECT_TYPE_TEXT}</button>
            <div className={open ? 'open items-menu' : 'items-menu'}>
                {
                    items?.map((item, index) => (
                        <div
                            key={index}
                            className={'itemContainer'}>
                            <NavLink
                                onClick={()=>setOpen(false)}
                                className='item'
                                aria-label={item.title}
                                to={item.link}>

                                <span>{item.title}</span>
                            </NavLink>

                        </div>
                    ))

                }


            </div>
        </div >
    )
}

export default SecondTopbarMobile