import React from 'react'
import { EcoFriendlyPage } from '../../data/pages';
import Intro from '../../components/intro/Intro';
import Features from '../../components/features/Features';
import PageTitle from '../../layout/pageTitle/PageTitle';
import { EcoFriendlyItems } from "../../data/menuItems";
import SecondTopbar from '../../layout/secondTopbar/SecondTopbar';
import MetaComponent from "../../MetaComponent";

const tags = ['projects','פרוייקטים', 'בניה ירוקה']


const EcoFriendly = () => {
    const { intro, featuresTitle, features } = EcoFriendlyPage;
    return (
        <>
            <MetaComponent
                title={"בנייה ירוקה חברת שיא עינב"}
                description={tags.toString()}
            />
            {/* <SecondTopbar items={EcoFriendlyItems} /> */}
            <div className="sections">
                <Intro title={intro.title} content={intro.content} backgroundImage={intro.image} />
                <div id="benefits">
                    <PageTitle title={featuresTitle} style={{ color: 'black' }} />
                    <Features features={features} />
                </div>

                {/* <About />
                <Services />
                <Team /> */}
            </div>
        </>
    )
}

export default EcoFriendly